import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {css, Theme} from "@emotion/react";
import {PaginationWithList} from "@web2/pagination";

import {AdPlacement} from "../../advertisement/components/AdPlacement";
import {PlacementDest} from "../../advertisement/PlacementDest";
import {PlacementPage} from "../../advertisement/PlacementPage";
import {appendQueryString} from "../../app/utils/append_query_string";
import {RequestState} from "../../app/utils/request_response_utils/factories/reduce_request_state";
import {parseSearch} from "../../app/utils/request_response_utils/parse_search";
import {validateQuery} from "../../app/utils/request_response_utils/validate_query";
import {ArrowLeftIcon} from "../../offer/list/components/icons/ArrowLeftIcon";
import {ArrowRightIcon} from "../../offer/list/components/icons/ArrowRightIcon";
import {scrollToTop} from "../../offer/list/components/OfferListList";
import {getHousingPlatformUrl} from "../../offer/utils/constants_offer";
import {ViewType} from "../../tracking/view_type/view_type";
import {ARTICLE_LIST_WIDE_BREAKPOINT, ArticleBox, IArticleBox} from "./ArticleBox";
import {ArticleBoxLoader} from "./ArticleBoxLoader";

import placeholder360x337 from "../../../assets/ad_placeholders/platforma_baner_360x337.png";

interface Props extends RouteComponentProps {
    articles: IArticleBox[];
    page_count: number;
    page: number;
    page_size: number;
    requestState: RequestState;
    scrollTopAfterPageChange?: boolean;
    viewType: ViewType;
    adTargeting?: string;
    disableAds?: boolean;
}

const placeholder = {
    image: placeholder360x337,
    url: getHousingPlatformUrl({source: "gh_articles"})
};

export const BlogArticleListC = (props: Props) => {
    const buildPageLink = (page: number) => {
        const query = validateQuery(["page"], parseSearch(props.location.search), page >= 2 ? {page: page.toString()} : {});
        return appendQueryString(props.location.pathname, query) + props.location.hash;
    };

    const onChangePage = (page: number) => {
        const query = validateQuery(["page"], parseSearch(props.location.search), page >= 2 ? {page: page.toString()} : {});
        props.history.push(appendQueryString(props.location.pathname, query) + props.location.hash);
        props.scrollTopAfterPageChange && scrollToTop();
    };

    return (
        <>
            <ul css={articleList}>
                {!props.disableAds && (
                    <li css={adPlacementHolder}>
                        <AdPlacement
                            dest={PlacementDest.MOBILE}
                            page={PlacementPage.article_list__mobile}
                            placeholder={placeholder}
                            breakpoint={ARTICLE_LIST_WIDE_BREAKPOINT}
                            target={props.adTargeting}
                        />
                    </li>
                )}

                {props.articles.map((article) => {
                    //TODO: remove article.uuid when articles will be from API1
                    const articleId = article.id || article.uuid;

                    if (props.requestState !== RequestState.Success) {
                        return <ArticleBoxLoader key={articleId} />;
                    }

                    return (
                        <li key={articleId}>
                            <ArticleBox article={article} />
                        </li>
                    );
                })}
            </ul>

            <div css={paginationHolder}>
                <PaginationWithList
                    onChangePageClick={onChangePage}
                    pageCount={props.page_count}
                    currentPage={props.page}
                    hrefBuilder={buildPageLink}
                    iconPrev={<ArrowLeftIcon />}
                    iconNext={<ArrowRightIcon />}
                    multiNumbersInside
                />
            </div>
        </>
    );
};

export const ArticleList = withRouter(BlogArticleListC);

const articleList = (theme: Theme) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
    list-style: none;
    padding: 0;

    &:after {
        content: "";
        display: block;
        width: 310px;
    }

    @media screen and (min-width: ${theme.breakpoints.screen_md}) {
        justify-content: space-between;
    }
`;

const paginationHolder = css`
    padding: 1rem 0;
`;

const adPlacementHolder = css`
    list-style: none;
    width: 100%;

    @media (min-width: ${ARTICLE_LIST_WIDE_BREAKPOINT}px) {
        display: none;
    }
`;
